type TypeBreakpoint = { min?: number, max?: number }
export type TypeSize = { width: number, height: number }
export type TypeSizeByDevice = {
  mobile: { width: string, height: string },
  desktop: { width: string, height: string }
}

// ================== IMAGES ================== //
export type TypeVectorImage = {
  type?: 'svg'
  src: string,
  alt: string,
  size?: TypeSize,
  content_type?: 'image/svg+xml'
}

// ================== VIDEO ================== //

export type TypeVideoExternal = {
  type: 'video',
  alt?: string,
  platform: 'vimeo' | 'youtube',
  short_video?: string | number,
  long_video?: string | number
}

enum EnumVideoMimeTypes {
  mp4 = 'video/mp4',
  mpg = 'video/mpeg',
  ogg = 'video/ogg',
  webm = 'video/webm'
}

type TypeVideoSource = {
  content_type: EnumVideoMimeTypes,
  url: string
  poster?: string,
  cover?: string,
}

type TypeVideoLocal = {
  type: 'video',
  mq?: TypeBreakpoint,
  m3u8?: string,
  poster?: string,
  cover?: string,
  size: TypeSize,
  sources: TypeVideoSource[]
}

type TypeVideo = TypeVideoExternal | TypeVideoLocal

export enum EnumMediaTypes {
  video = 'video',
  image = 'image',
  svg = 'svg'
}

export type TypeMediaImage = {
  type: 'image'
  alt?: string,
  default: {
    size: { width: number, height: number },
    src: string
  },
  mobile?: {
    size: { width: number, height: number },
    src: string
  }
}

export type TypeMedia = TypeVectorImage | TypeMediaImage | TypeVideo
