import type { TypeCMSOwner, TypeCMSTask } from "./cms"

export type TypeCMSProjectType = 'independent' | 'monthly' | 'research'

export enum EProjectType {
  RECURRING = 'recurring',
  INDIVIDUAL = 'individual'
}

export enum EProjectStatus {
  ACTIVE = 0,
  INACTIVE = 1
}

export type TypeProjectExpertise = {
  total: number,
  current: number,
  expertise: {
    name: string
  }
}

export type TypeCMSProject = {
  id: number,
  name: string,
  status: EProjectStatus,
  type: EProjectType,
  project_scope?: string
  start_date?: Date | string
  end_date?: Date | string
  client: {
    title: string
  },
  owners?: TypeCMSOwner[]
  project_type?: TypeCMSProjectType
  bugs?: number
  project_expertises?: TypeProjectExpertise[]
  tasks?: TypeCMSTask[]
}

export type TypeCMSProjects = TypeCMSProject[]
