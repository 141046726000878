'use client'
import { type FC, memo } from 'react'
import styled from 'styled-components'
import { font13_18, rgbAlpha, vw, mq } from '@/styles'

type Props = {
  data: any,
  readonly type?: 'hours' | 'simple' | 'client',
  readonly color?: string
}

export const Result: FC<Props> = memo(({ data}) => {
  return (
    <Article>
      <hgroup>
        <h1>{data.name}</h1>
        <h2>{data.client.title}</h2>
      </hgroup>
    </Article>
  )
})

const Article = styled.article`
  border-radius: 10px;
  color: var(--color-background);
  display: flex;
  display: flex;
  flex-direction: row;
  height: 25px;
  max-height: ${vw(60, 'mobile')};
  padding: 0;
  width: 100%;

  ${mq.greaterThan('tablet')} {
    max-height: ${vw(85, 'desktop')};
  }
  ${mq.greaterThan('desktop')} {
    max-height: 85px;
  }

  hgroup {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    gap: 10px;
    h1 {
      ${font13_18(true, 400)}
      color: ${rgbAlpha('--color-white', .5)};
      ${mq.greaterThan('tablet')} {
        ${font13_18(false, 400)}
      }
    }
    h2 {
      ${font13_18(true, 400)}
      color: var(--color-white);
      ${mq.greaterThan('tablet')} {
        ${font13_18(false, 400)}
      }
    }
  }
`
