'use client'
import { type FC, memo, useState, Dispatch, SetStateAction, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { type UseFormRegister, type UseFormSetValue } from 'react-hook-form'
import { iconByName } from '@/utils'
import type { TypeCMSProjects } from '@/schema'
import { mq, vw, font13_19, rgbAlpha } from '@/styles'
import { Figure } from './Figure'
import { Result } from './Result'

type Props = {
  projects: TypeCMSProjects,
  register: UseFormRegister<any>
  setValue: UseFormSetValue<any>
  setStep?: Dispatch<SetStateAction<number>>
  shouldShowResultsBeforeSearch: boolean,
  setShouldShowResultsBeforeSearch: Dispatch<SetStateAction<boolean>>
  title?: string
  placeHolderVisible?: boolean
  isCheckbox?: boolean
  fancyValues?: any
  setFancyValues?: Dispatch<SetStateAction<any>>
  isDayTask?: boolean
  onClickResult?: (data) => void
  shouldDisplayScrollBar?: boolean
  isClient?: boolean
}

const Options = ({ data, setValue, register, setStep, isCheckbox, searchItem, title, setSearchItem, setShouldShowResultsBeforeSearch, setFancyValues, onClickResult, isClient }) => {
  const cards = []
  if (!isCheckbox) {
    cards.unshift(
      <button onClick={(_e) => {
        searchItem && setValue(title?.toLowerCase(), searchItem)
        setShouldShowResultsBeforeSearch(false)
       }} {...register}>Add new {title?.toLowerCase()} <span>{searchItem}</span></button>
    )
    data.map((project, idx) => {
      cards.push(
        <Li key={idx} onClick={() => {
          if (title.toLowerCase() === 'client') {
            setSearchItem(project.client.title)
            setValue(title.toLowerCase(), project.client.title)
            setValue('name', project.name)
            setFancyValues({ client: project.client.title, name: project.name })
            setShouldShowResultsBeforeSearch(false)
          } else {
            setSearchItem(project.name)
            setValue('client', project.client.title)
            setValue(title.toLowerCase(), project.name)
            setFancyValues({ client: project.client.title, name: project.name })
            setShouldShowResultsBeforeSearch(false)
          }
        }} {...register}>
          <Result data = {project} />
        </Li>
      )
    })
  }
  else {
    data.map((project, idx) => {
      cards.push(
        <Li key={idx}>
          <Label onClick={(e) => {
            if (onClickResult) {
              e.preventDefault()
              if (!isClient) onClickResult({ projectName: project.name, clientName: project.client.title })
              else onClickResult(project.id)
              return
            }
            setValue(`project.${project.name} - ${project.client.title}`,true)
          }}>
            <input name={`project.${project.name}`} className='visually-hidden' type="checkbox" defaultChecked={false} {...register(`project.${project.name}`)} />
            <button onClick={() => {
              setStep && setStep(2)
            }}>
              <Result data = {project} />
            </button>
            </Label>
        </Li>
      )
    })
  }
  return cards
}

function updateScrollIndicator(scrollContainer, setScrollIndicatorPosition, setScrollBarHidden) {
  const containerHeight = scrollContainer.clientHeight
  const contentHeight = scrollContainer.scrollHeight
  const scrollTop = scrollContainer.scrollTop
  const scrollBarMaxHeight = 296

  if (contentHeight > containerHeight) {
    setScrollBarHidden(false)
  } else {
    setScrollBarHidden(true)
  }

  const scrollableArea = contentHeight - scrollBarMaxHeight
  const scrollPercentage = scrollableArea > 0 ? scrollTop / scrollableArea : 0
  const indicatorHeight = Math.max(50, (containerHeight / contentHeight) * scrollBarMaxHeight)
  const maxIndicatorTravel = contentHeight - indicatorHeight
  const indicatorPosition = (scrollPercentage * maxIndicatorTravel)
  const threshold = 0.99
  if (scrollPercentage > threshold) {
    console.log('scrollPercentage', scrollPercentage)
    console.log('maxIndicatorTravel', maxIndicatorTravel)
    setScrollIndicatorPosition(maxIndicatorTravel) // Set to the maximum position
    return
  }

  setScrollIndicatorPosition(indicatorPosition)
}


export const Search: FC<Props> = memo(({ projects, register, setValue, setStep, shouldShowResultsBeforeSearch, setShouldShowResultsBeforeSearch, title, placeHolderVisible = true, isCheckbox = true, fancyValues, setFancyValues, isDayTask = false, onClickResult, shouldDisplayScrollBar = true, isClient= false }) => {
  const [searchItem, setSearchItem] = useState('')
  const [filteredProjects, setFilteredProjects] = useState(projects)
  const [initialStateDisplayResults] = useState(shouldShowResultsBeforeSearch)
  const [scrollIndicatorPosition, setScrollIndicatorPosition] = useState(0)
  const [scrollBarHidden, setScrollBarHidden] = useState(true)
  const scrollBarContainerRef = useRef(null)

  const handleInputChange = useCallback((e) => {
    const searchTerm = e.target.value
    setSearchItem(searchTerm)
    setShouldShowResultsBeforeSearch(true)

    const filteredItems = projects.filter((project) =>
      project.client.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    )

    setFilteredProjects(filteredItems)
    if (searchTerm === '') {
      setShouldShowResultsBeforeSearch(initialStateDisplayResults)
    }
  }, [projects, initialStateDisplayResults, setShouldShowResultsBeforeSearch])

  const handleMouseDown = (e) => {
    const scrollBarMaxHeight = 296
    const startY = e.clientY
    const startScrollTop = scrollBarContainerRef.current.scrollTop

    const handleMouseMove = (e) => {
      const deltaY = e.clientY - startY
      const newScrollTop = startScrollTop + (deltaY / scrollBarMaxHeight) * scrollBarContainerRef.current.scrollHeight
      scrollBarContainerRef.current.scrollTop = newScrollTop
    }

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  useEffect(() => {
    if (fancyValues?.length > 0) setSearchItem(fancyValues)
  }, [fancyValues])

  useEffect(() => {
    if (!scrollBarContainerRef.current) return

    const handleScroll = () => {
      updateScrollIndicator(scrollBarContainerRef.current, setScrollIndicatorPosition, setScrollBarHidden)
    }

    scrollBarContainerRef.current.addEventListener('scroll', handleScroll)

    handleScroll()

    return () => {
      if (!scrollBarContainerRef.current) return
      scrollBarContainerRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [scrollBarContainerRef.current])


  return (
    <Div $isDayTask={isDayTask}>
      {!isDayTask && <label>
        {title ? <span>{title}</span> : <Figure media={iconByName('lupa')} />}
        <input type='text' value={ searchItem } onChange={handleInputChange} placeholder={placeHolderVisible ? 'Type to search' : ''} />
        {searchItem && <Figure media={iconByName('close')} onClick={() => { setSearchItem('')
          setShouldShowResultsBeforeSearch(initialStateDisplayResults)
        } } />}
      </label>}
      {shouldShowResultsBeforeSearch && (
        <ul>
          <Ul ref={scrollBarContainerRef} $scrollBarHidden={scrollBarHidden} $isDayTask={isDayTask} $shouldDisplayScrollBar={shouldDisplayScrollBar}>
            {/* {Results} */}
            <Options data={filteredProjects} setValue={setValue} register={register} setStep={setStep} isCheckbox={isCheckbox} searchItem={searchItem} title={title} setSearchItem={setSearchItem} setShouldShowResultsBeforeSearch={setShouldShowResultsBeforeSearch} setFancyValues={setFancyValues} onClickResult={onClickResult} isClient={isClient}/>
            {shouldDisplayScrollBar && <div className='scroll-bar-container' >
              <svg className='scroll-background' xmlns='http://www.w3.org/2000/svg' width='4' height='298' viewBox='0 0 4 298' fill='none'>
                <path d='M2 1.5L2 296.5' stroke='#FFFBFB' strokeOpacity='0.02' strokeWidth='3' strokeLinecap='round' />
              </svg>
              <svg className='scroll-indicator' onMouseDown={handleMouseDown} style={{ transform: `translateY(${scrollIndicatorPosition}px)`}} xmlns='http://www.w3.org/2000/svg' width='4'  height='73' viewBox='0 0 4 73'  fill='none'>
                <path d='M2 2L2 71' stroke='#FFFBFB' strokeOpacity='0.3' strokeWidth='3' strokeLinecap='round' />
              </svg>
            </div>}
          </Ul>
        </ul>
      )}
    </Div>
  )
})


const Div = styled.div<{$isDayTask}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  height: 100%;

  > label {
    align-items: center;
    background-color: ${({ $isDayTask }) => $isDayTask ? 'transparent' : 'rgba(255,255,255,.05)'};
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 50px;
    margin-top: 30px;
    padding: 0px 15px;
    width: 100%;

    > span {
      ${font13_19(true, 400)}
      color: ${rgbAlpha('--color-white', 0.3)};

      ${mq.greaterThan('tablet')} {
        ${font13_19(false, 400)}
      }
    }

    > figure {
      width:13px;
      height: 13px;
    }

    > input {
      align-items: flex-end;
      background-color: transparent;
      color: white;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      width: 100%;
    }
  }

  > ul {
    position: relative;
    max-width: 100%;
    min-width: ${({ $isDayTask }) => $isDayTask ? '300px' : 'unset'};
  }
`
const Label = styled.label`
  align-items: center;
  border-radius: ${vw(10, 'mobile')};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    border-radius: ${vw(10, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    border-radius: ${vw(10, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    border-radius: 10px;
  }

  button {
    font-size: ${vw(40, 'mobile')};
    height: 100%;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      font-size: ${vw(40, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      font-size: 40px;

    }
  }

  &.disabled {
    opacity: .3;
  }

  &:has(input[type=checkbox]:checked) {
    background-color:rgba(255,255,255,.4);
    opacity: .4;
  }


  .visually-hidden {
    left: -100vw;
    position: absolute;
  }
`

const Ul = styled.ul<{$scrollBarHidden:boolean, $isDayTask:boolean, $shouldDisplayScrollBar: boolean}>`
  align-items: flex-start;
  align-self: stretch;
  background-color: ${({ $isDayTask }) => $isDayTask ? 'unset' : 'rgba(255,255,255,.02)'};
  border-radius: ${({ $isDayTask }) => $isDayTask ? '0' : '20px'};
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  gap: 20px;
  position: relative;
  top: 0;
  padding: ${({ $isDayTask }) => $isDayTask ? '0' : '30px 20px 30px 30px'};
  max-height: ${({ $shouldDisplayScrollBar }) => $shouldDisplayScrollBar ? '292px' : 'unset'};
  overflow-y: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none; /* Webkit browsers */
  }
  .scroll-bar-container {
    align-items: center;
    display: ${({ $scrollBarHidden }) => $scrollBarHidden ? 'none' : 'flex'};
    flex-shrink: 0;
    height: 280px;
    justify-content: center;
    position: absolute;
    right: ${({ $isDayTask }) => $isDayTask ? '0' : '20px'};
    top: 0;
    width: 5px;
  }

  .scroll-background {
    position: fixed;
    width: 5px;
    height: 280px;
    flex-shrink: 0;
    padding: 15px 0;
    stroke-width: 3px;
    stroke: rgba(255, 251, 251, 0.02);
  }

  .scroll-indicator {
    position: absolute;
    width: 5px;
    flex-shrink: 0;
    stroke-width: 3px;
    stroke: rgba(255, 251, 251, 0.3);
    top: 15px;
    z-index: 10000;
    pointer-events: all;
  }

`

const Li = styled.li`
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  pointer-events: all;
  width: 100%;
`
