// import { getLocaleCountry } from '@dy/commons/utils'

// const NEXT_PUBLIC_LOCALE = process.env.NEXT_PUBLIC_LOCALE
// const localeCountry = getLocaleCountry(NEXT_PUBLIC_LOCALE)
// const LOCALE = localeCountry.split('-')[0]
// const REGION = localeCountry.split('-')[1]
// const DY_API_URL = `${process.env.DY_API}/${REGION}/${LOCALE}`
const DY_API_URL = `${process.env.DY_API}`

export const endpoints = {
  'auth-login': () => `${DY_API_URL}/test`, // TEST endpoint ONLY
  'auth-signup': () => `${DY_API_URL}/test`, // TEST endpoint ONLY
  'auth-reset-password': (query) => `${DY_API_URL}/test/${query.user_id}`, // TEST endpoint ONLY
}
