export * from './media'
export * from './cms'
export * from './cms_project'
export * from './translator'


type TypeHrefPathname = '/' | '/people' | '/people/[slug]' | 'cities' | 'city/[slug]' | '/[city]/[slug]' | string

export type TypeHref = {
  pathname: TypeHrefPathname, // TODO FIXED PATHNAME VALUES
  query?: {
    [key: string]: string | number | boolean
  }
} | string
