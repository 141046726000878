import { type TypeCMSProjects, EProjectStatus, EProjectType } from '@/schema'

export const data:TypeCMSProjects = [
  {
    id: 1,
    name: 'CMS 3.0',
    start_date: '2023-04-01',
    end_date: '2023-04-30',
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    client: {
      title: 'Digital YOU',
    },
    owners: [
      {
        avatar: 'https://picsum.photos/200/300',
        name: 'Héctor'
      }
    ],
    bugs: 3,
    project_expertises: [
      {
        total: 30,
        current: 29,
        expertise: {
          name: 'UX'
        }
      },
      {
        total: 40,
        current: 5,
        expertise: {
          name: 'dev'
        }
      },
      {
        total: 10,
        current: 1,
        expertise: {
          name: 'dir'
        }
      }
    ]
  },
  {
    id: 2,
    name: 'Mammafiore B2B',
    start_date: '2023-04-01',
    end_date: '2023-04-30',
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    client: {
      title: 'Mammafiore Distribution',
    },
    owners: [
      {
        avatar: 'https://picsum.photos/200/300',
        name: 'Alex'
      }
    ],
    bugs: 3,
    project_expertises: [
      {
        total: 31,
        current: 29,
        expertise: {
          name: 'UX'
        }
      },
      {
        total: 40,
        current: 95,
        expertise: {
          name: 'dev'
        }
      },
      {
        total: 10,
        current: 12,
        expertise: {
          name: 'dir'
        }
      }
    ]
  },
  {
    id: 3,
    name: 'Virbac Polonia',
    start_date: '2023-04-01',
    end_date: '2023-04-30',
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    client: {
      title: 'Virbac SA',
    },
    owners: [
      {
        avatar: 'https://picsum.photos/200/300',
        name: 'Lluis'
      }
    ],
    bugs: 3,
    project_expertises: [
      {
        total: 31,
        current: 15,
        expertise: {
          name: 'UX'
        }
      },
      {
        total: 300,
        current: 290,
        expertise: {
          name: 'dev'
        }
      },
      {
        total: 20,
        current: 5,
        expertise: {
          name: 'dir'
        }
      }
    ]
  },
  {
    id: 4,
    name: 'Hellomamma EVO',
    start_date: '2023-12-01',
    end_date: '2023-12-31',
    type: EProjectType.RECURRING,
    status: EProjectStatus.ACTIVE,
    client: {
      title: 'Mammafiore Distribution',
    },
    owners: [
      {
        avatar: 'https://picsum.photos/200/300',
        name: 'Alex'
      }
    ],
    bugs: 2,
    project_expertises: [
      {
        total: 60,
        current: 3,
        expertise: {
          name: 'UX'
        }
      },
      {
        total: 250,
        current: 300,
        expertise: {
          name: 'dev'
        }
      },
      {
        total: 15,
        current: 8,
        expertise: {
          name: 'dir'
        }
      }
    ]
  },
  {
    id: 5,
    name: 'Cuvée 3000 EVO',
    start_date: '2023-12-01',
    end_date: '2023-12-31',
    type: EProjectType.RECURRING,
    status: EProjectStatus.ACTIVE,
    client: {
      title: 'Cuvée 3000',
    },
    owners: [
      {
        avatar: 'https://picsum.photos/200/300',
        name: 'Héctor'
      }
    ],
    bugs: 1,
    project_expertises: [
      {
        total: 5,
        current: 2,
        expertise: {
          name: 'UX'
        }
      },
      {
        total: 10,
        current: 6.5,
        expertise: {
          name: 'dev'
        }
      },
      {
        total: 10,
        current: 5,
        expertise: {
          name: 'dir'
        }
      }
    ]
  },
  {
    id: 6,
    name: 'Next.js 14 Suspense',
    start_date: '2023-04-01',
    end_date: '2023-04-30',
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    client: {
      title: 'Digital YOU',
    },
    owners: [
      {
        avatar: 'https://picsum.photos/200/300',
        name: 'Noemi'
      }
    ],
    bugs: 3,
    project_expertises: [
      {
        total: 0,
        current: 0,
        expertise: {
          name: 'UX'
        }
      },
      {
        total: 16,
        current: 2,
        expertise: {
          name: 'dev'
        }
      },
      {
        total: 1,
        current: 0,
        expertise: {
          name: 'dir'
        }
      }
    ]
  },
]

export const projects:TypeCMSProjects = [
  {
    id: 1,
    name: 'tempus2 Digital',
    client: {
      title: 'DY'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 2,
    name: 'sablos Digital',
    client: {
      title: 'Sablos'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 3,
    name: 'luzio Digital',
    client: {
      title: 'Luzio'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 4,
    name: 'hellomamma Digital',
    client: {
      title: 'Mammafiore'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 5,
    name: 'Mammafiore Digital',
    client: {
      title: 'Mammafiore'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 6,
    name: 'Ayb Digital',
    client: {
      title: 'Ayb hislabor'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 7,
    name: 'Portella Digital',
    client: {
      title: 'Portella'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 8,
    name: 'LoSiento Digital',
    client: {
      title: 'LoSiento'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 9,
    name: 'LoReSiento Digital',
    client: {
      title: 'LoReSiento'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 10,
    name: 'Nexia Digital',
    client: {
      title: 'Nexia'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 11,
    name: 'Virbac Digital',
    client: {
      title: 'Virbac'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.ACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
  {
    id: 12,
    name: 'Testing Digital',
    client: {
      title: 'Test'
    },
    type: EProjectType.INDIVIDUAL,
    status: EProjectStatus.INACTIVE,
    project_scope: '',
    start_date: new Date('2024-03-01'),
    end_date: new Date('2024-03-01'),
    bugs: 0,
    project_expertises: [{
      total: 20,
      current: 12,
      expertise: {
        name: 'UX'
      }
    }],
  },
]


export const owners =
  [
    {
        "id": "0",
        "name": "Susie Cruz",
        "avatar": "/images/mock/52.jpg"
    },
    {
        "id": "1",
        "name": "Logan Robinson",
        "avatar": "/images/mock/35.jpg"
    },
    {
        "id": "2",
        "name": "Stilla Niedermeier",
        "avatar": "/images/mock/32.jpg"
    },
    {
        "id": "3",
        "name": "Venla Manner",
        "avatar": "/images/mock/53.jpg"
    },
    {
        "id": "4",
        "name": "Alexander Kristensen",
        "avatar": "/images/mock/76.jpg"
    },
    {
        "id": "5",
        "name": "Ema Pinto",
        "avatar": "/images/mock/78.jpg"
    },
    {
        "id": "6",
        "name": "Prithvi Saniel",
        "avatar": "/images/mock/68.jpg"
    },
    {
        "id": "7",
        "name": "Iida Lampinen",
        "avatar": "/images/mock/64.jpg"
    },
    {
        "id": "8",
        "name": "Milagros Morales",
        "avatar": "/images/mock/88.jpg"
    },
    {
        "id": "9",
        "name": "Jacobo Alvarez",
        "avatar": "/images/mock/37.jpg"
    }
]
