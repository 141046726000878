'use client'
import React, { type FC, memo, useMemo, useState, useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useForm } from 'react-hook-form'
import { mq, vw, font20_25, font13_18, rgbAlpha, font13_19, font12_19, font11_13 } from '@/styles'
import { iconByName } from '@/utils'
import { useDeviceType } from '@/hooks'
import { projects } from '@/api'
import { Figure } from './Figure'
import { TextArea } from './inputs'
import { Search } from './Search'

type Props = {
  data: any,
  readonly type?: 'day' | 'week'
  readonly isGrouped? : boolean
}

export function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

const transformDurationToTime = (duration: string) => {
  const durationFloat = parseFloat(duration)
  const hours = Math.floor(durationFloat)
  const minutes = Math.round((durationFloat - hours) * 60)

  return { hours, minutes }
}

const getTags = (tags, setExpertise, register, type, activeTags?:any) => tags.map((tag, idx) => {
  const isActive = activeTags?.map(t => t.toLowerCase()).includes(tag.toLowerCase())

  return <button className={`tag ${isActive ? 'active' : 'task_tag'}`} key={idx} onClick={(e) => {
    e.preventDefault()
    if (type === 'task_tags') {
      setExpertise(prevTags => {
        let updatedTags = [...prevTags];
        if (updatedTags.map(t => t.toLowerCase()).includes(tag.toLowerCase())) {
          updatedTags = updatedTags.filter(t => t.toLowerCase() !== tag.toLowerCase())
        } else {
          updatedTags.push(tag)
        }
        register(type, { value: updatedTags })
        return updatedTags
      })
    } else {
      setExpertise(tag)
      register(type, { value: tag })
    }
  }
  }>{tag}</button>
})

const projectExpertises = [
  'UX',
  'DEV',
  'DIR'
]

const projectTaskType = [
  'Creation',
  'Research',
  'Screw-ups'
]

const projectTaskTag = [
  'layout',
  'CMS',
  'API',
  'CMSinteg',
  '3P integ',
  'devOps',
  'content',
  'data',
  'proposal',
  'briefing',
  'meeting'
]

const getTaskTags = (tags) => tags.map((tag, idx) =>  <span className='tag_selected' key={idx}>{tag}</span>)

const Task = ({ task, register, setValue }) => {
  const { isMobile } = useDeviceType()

  const [expandedTime, setExpandedTime] = useState(false)
  const [expandedExpertised, setExpandedExpertised] = useState(false)
  const [expandedTaskType, setExpandedTaskType] = useState(false)
  const [expandedTaskTags, setExpandedTaskTags] = useState(false)
  const [expandedEditDots, setExpandedEditDots] = useState(false)

  const [time, setTime] = useState(transformDurationToTime(task.duration))
  const [expertise, setExpertise] = useState(task.expertise.name)
  const [taskType, setTaskType] = useState(task.task_type)
  const [taskTags, setTaskTags] = useState(task.task_tags)
  const [comments, setComments] = useState(task.description)

  const timeRef = useRef(null)
  const expertiseRef = useRef(null)
  const taskTypeRef = useRef(null)
  const taskTagsRef = useRef(null)
  const editDotsRef = useRef(null)

  const Expertise = useMemo(() => getTags(projectExpertises, setExpertise, register, 'expertise'), [setExpertise, register])
  const TaskType = useMemo(() => getTags(projectTaskType, setTaskType, register, 'task_type'), [setTaskType, register])
  const TaskTagsOptions = useMemo(() => getTags(projectTaskTag, setTaskTags, register, 'task_tags', taskTags), [setTaskTags, register, projectTaskTag, taskTags])
  const TaskTags = useMemo(() => getTaskTags(taskTags), [taskTags])

  useOutsideClick(timeRef, () => setExpandedTime(false))
  useOutsideClick(expertiseRef, () => setExpandedExpertised(false))
  useOutsideClick(taskTypeRef, () => setExpandedTaskType(false))
  useOutsideClick(taskTagsRef, () => setExpandedTaskTags(false))
  useOutsideClick(editDotsRef, () => setExpandedEditDots(false))

  const handleOnBlur = useCallback( async() => {
    setValue('time', time)
    setExpandedTime(false)
  }, [setValue, time])

  const handleChange = useCallback( async (e) => {
    const newValue = e.target.value
    if (!newValue.startsWith(comments)) {
      setComments(comments + newValue.replace(comments, ''))
    } else {
      setComments(newValue)
    }
  }, [comments])


  const incrementTime = (type, e) => {
    e.preventDefault()
    setTime(prevTime => {
      let updatedTime = { ...prevTime }
      if (type === 'hours') {
        updatedTime.hours++
      }
      if(type === 'minutes') {
        updatedTime.minutes++
        if (updatedTime.minutes >= 60) {
          updatedTime.hours++
          updatedTime.minutes = 0
        }
      }
      setValue('time', updatedTime)
      register('time', { value: updatedTime })
      return updatedTime
    })
  }

  const decrementTime = (type,e) => {
    e.preventDefault()
    setTime(prevTime => {
      let updatedTime = { ...prevTime }
      if (type === 'hours') {
        if (updatedTime.hours === 0) return
        updatedTime.hours--
      }
      if(type === 'minutes') {
        if (updatedTime.minutes > 0) {
          updatedTime.minutes--
        } else if (updatedTime.hours > 0) {
          updatedTime.hours--
          updatedTime.minutes = 59
        }
      }
      setValue('time', updatedTime)
      register('time', { value: updatedTime })
      return updatedTime
    })
  }

  const onChange = (e) => {
    const { name, value } = e.target
    if(value.length > 2 || value.length === 0) return
    setTime(prevTime => {
      let updatedTime = { ...prevTime }
      updatedTime[name] = parseInt(value)
      return updatedTime
    })
  }

  return (
    <li>
      <div>
        <div ref={timeRef} className='time' onClick={() => {!isMobile && setExpandedTime(true)}}>
          <div className='hours base'>
          <InputStyled type='number' min='0' max='12' name='hours' placeholder={time.hours === 0 ? '00' : time.hours.toString()} onChange={(e) => onChange(e)} onBlur={handleOnBlur} />
            { time.minutes > 0 &&
              <>
                <span>.</span>
                <InputStyled className={'minutes'} type='number' min='0' max='60' name='minutes' placeholder={time.minutes === 0 ? '00' : time.minutes.toString()} onChange={(e) => onChange(e)} onBlur={handleOnBlur} />
              </>
            }
            <span>h</span>
          </div>
          <Hidden className='actions' $expanded={expandedTime}>
            <button onClick={(e) => { incrementTime('hours',e) }}>
              <Figure media={iconByName('chevron-up')} />
            </button>
            <button disabled={time.hours === 0} onClick={(e) => decrementTime('hours', e)}>
              <Figure media={iconByName('chevron-down')} />
            </button>
            <button disabled={time.minutes === 0} onClick={(e) => decrementTime('minutes', e)}>
              <Figure media={iconByName('chevron-left')} />
            </button>
            <button onClick={(e) => {incrementTime('minutes', e) }}>
              <Figure media={iconByName('chevron-right')} />
            </button>
          </Hidden>
        </div>
        {isMobile &&
          <>
            <div className='mobile_tags'>
              <p>{expertise}</p>
              <p>{taskType}</p>
              {TaskTags}
            </div>
          </>
        }
        {!isMobile && <div>
          <div className='time' ref={expertiseRef} onClick={() => {!isMobile && setExpandedExpertised(true)}} >
            <div className='hours'>
              <p>{expertise}</p>
            </div>
            <Hidden className='actions' $expanded={expandedExpertised}>
              {Expertise}
            </Hidden>
          </div>
          <div className='time' ref={taskTypeRef} onClick={() => {!isMobile && setExpandedTaskType(true)}} >
            <div className='hours'>
              <p>{taskType}</p>
            </div>
            <Hidden className='actions' $expanded={expandedTaskType}>
              {TaskType}
            </Hidden>
          </div>
          <div className='time' ref={taskTagsRef} onClick={() => {!isMobile && setExpandedTaskTags(true)}} >
            <div className='hours high'>
              <p>{TaskTags}</p>
            </div>
            <Hidden className='actions task_tags' $expanded={expandedTaskTags}>
              {TaskTagsOptions}
            </Hidden>
          </div>
        </div>}
        {!isMobile &&<div>
          <TextArea name='comments' register={register} onChange={handleChange} defaultValue={comments} />
        </div>}
        <div className='time dots' ref={editDotsRef} onClick={(e) => {
          e.preventDefault()
          setExpandedEditDots(true)}}>
          <button><Figure media={iconByName('3dots')} /></button>
          <Hidden className='actions dot_action' $expanded={expandedEditDots}>
            <button>
              Edit
            </button>
            <button>
              Duplicate
            </button>
            <button>
              Delete
            </button>
          </Hidden>
        </div>

      </div>
      {isMobile && <TextArea name='comments' register={register} onChange={handleChange} defaultValue={comments} />}
    </li>
  )
}

const getTasks = (data: any, register, setValue) => {
  if(!data.tasks) return
  return data.tasks.map((task: any, idx: number) => {
    return <Task key={idx} task={task} register={register} setValue={setValue}/>
  })
}

const sumDurations = (tasks) => tasks.reduce((total, task) => total + task.duration, 0)

export const Card: FC<Props> = memo(({ data, type = 'day', isGrouped = false }) => {
  const { setValue, register } = useForm()
  const Tasks = useMemo(() => getTasks(data, register, setValue), [data])
  const totalHoursDay = useMemo(() => type === 'day' && sumDurations(data.tasks), [data.tasks, type])
  const { isMobile } = useDeviceType()
  const [expandedProjectName, setExpandedProjectName] = useState(false)
  const [projectName, setProjectName] = useState(data.projectName ?? '')
  const [clientName, setClientName] = useState(data.clientName ?? '')
  const projectNameRef = useRef(null)

  useOutsideClick(projectNameRef, () => setExpandedProjectName(false))

  const onClick = useCallback((data) => {
    const { projectName, clientName } = data
    setProjectName(projectName)
    setClientName(clientName)
  }, [setProjectName, setClientName, setExpandedProjectName, expandedProjectName])


  useEffect(() => {
    if (clientName !== '') {
      setExpandedProjectName(false)
    }
  }, [clientName])

  return (
    <Article $type={type} $isGrouped={isGrouped}>
      {type === 'day' ?
        <form>
          {/* <CustomSelect name='project' options={options} defaultValue={`${data.projectName} ${data.clientName}`} setFormValue={setValue}>
          </CustomSelect> */}
          <hgroup ref={projectNameRef} onClick={() => {setExpandedProjectName(true)}}>
            <h2>{projectName}</h2>
            <h3>{clientName}</h3>
            {isMobile && <div className='total'><span>{totalHoursDay}h</span></div>}
            <Hidden $expanded={expandedProjectName} className='projects'>
              <Search projects={projects} register={register} setValue={setValue} title='Project' shouldShowResultsBeforeSearch={true} setShouldShowResultsBeforeSearch={() => {setExpandedProjectName(!expandedProjectName) }} isDayTask={true} onClickResult={onClick}/>
            </Hidden>
          </hgroup>
          <ul>
            {Tasks}
          </ul>
        </form>
        :
        <>
          <hgroup>
            <div>
              {!isGrouped && <h3>{data.project[0].client.title} </h3>}
              <span>{data.duration}h</span>
            </div>
            <p>{data?.project[0].name}</p>
          </hgroup>
        </>
      }
    </Article>
  )
})

export const Hidden = styled.div<any>`
  align-items: center;
  backdrop-filter: blur(6px);
  background-color: ${rgbAlpha('--color-purple', .3)};
  border-radius: 20px;
  bottom: -60px;
  display: ${({ $expanded }) => $expanded ? 'flex' : 'none'};
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  left: -35px;
  max-width: 432px;
  min-height: 70px;
  min-width: 170px;
  padding: 15px;
  position: absolute;
  z-index:5;

  &.projects {
    bottom: unset;
    left: unset;
  }

  > button {
    align-items: center;
    background: ${rgbAlpha('--color-white', 0.02)};
    border-radius:20px;
    display: flex;
    height: 25px;
    justify-content: center;
    width: 25px;

    > figure {
      height: 5px;
      width: 10px;
    }

    &.tag {
      align-items: center;
      background-color: ${rgbAlpha('--color-white', 0.3)};
      border-radius: 30px;
      display: flex;
      gap: 10px;
      justify-content: center;
      padding: 10px 15px;
      width: auto;
    }

    &.task_tag {
      background-color: ${rgbAlpha('--color-white', 0.02)};
      transition: background-color 300ms ease;

      &:hover,
      &:active {
        background-color: ${rgbAlpha('--color-white', 0.3)};
      }
    }
  }

  button:nth-of-type(3), button:nth-of-type(4) {
    > figure {
      height: 10px;
      width: 5px;
    }
  }

  &.task_tags {
    bottom: -140px;
  }

  &.dot_action {
    align-items: flex-start;
    background-color: ${rgbAlpha('--color-white', .02)};
    bottom: -130px;
    flex-direction: column;
    gap: 10px;
    left: -130px;
    padding: 15px;

    > button {
      background-color: unset;
      border-radius: unset;
      color: var(--color-text);
      display: flex;
      justify-content: flex-start;
      padding: unset;
      width: 100%;
    }
  }
`

const day = css`
  border-radius: 20px;

  > form {
    align-items: flex-start;
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    grid-template-columns:unset;
    padding: ${vw(10, 'mobile')} ${vw(20, 'mobile')};
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(10, 'tablet')} ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      display: grid;
      grid-template-columns: 30% auto;
      padding: ${vw(10, 'desktop')} ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 10px 20px;
    }

    > hgroup {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      gap: ${vw(10, 'mobile')};
      gap: 10px;
      min-height: 30px;
      padding-top: 24px;
      position: relative;

      ${mq.greaterThan('nexus7')} {
        gap: ${vw(10, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        align-items: flex-start;
        gap: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        gap: 10px;
      }

      > h2 {
        ${font12_19(true, 400)}
        grid-column: 1;
        grid-row:1;
        margin-bottom: 0;
        text-align: left;
        text-transform: uppercase;

        ${mq.greaterThan('tablet')} {
          ${font20_25(false, 400)}
          text-transform: none;
        }
      }

      > h3, span {
        ${font12_19(true, 400)}
        align-items: flex-end;
        color: ${rgbAlpha('--color-text', .15)};
        display: flex;
        gap: 10px;
        line-height: 13px;
        overflow: hidden;
        padding: 0;
        text-overflow: ellipsis;
        text-transform: uppercase;

        ${mq.greaterThan('tablet')} {
          ${font13_18(false, 400)}
          padding: 7px 0px;
        }
      }

      .total {
        margin-left: auto;
        > span {
          text-transform: none;
        }
      }
    }

    > ul {
      display: flex;
      flex-direction: column;
      width: 100%;

      > li {
        width: 100%;

        > div {
          align-items: flex-start;
          display: flex;
          gap:10px;
          grid-template-columns: unset;
          padding: 15px 0;
          position: relative;

          ${mq.greaterThan('tablet')} {
            display: grid;
            gap: 40px;
            grid-template-columns: 70px 335px auto 20px;
          }

          > div {
            align-content: center;
            align-items: flex-start;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap:10px;

            ${mq.greaterThan('tablet')} {
              gap: 20px;
            }

            &:first-of-type {
              span:not(.hours > span) {
                ${font20_25(true, 600)}

                ${mq.greaterThan('tablet')} {
                  ${font20_25(false, 600)}
                }
              }
            }

            p {
              ${font13_18(true, 600)}

              ${mq.greaterThan('tablet')} {
                ${font13_18(false, 600)}
              }
            }


            &:last-of-type {
              > p {
                ${font13_18(true, 600)}

                ${mq.greaterThan('tablet')} {
                  ${font13_18(false, 600)}
                }
              }
            }

            > div:not(.actions, .hours) {
              display: flex;
              flex-direction: row;
              gap: 10px;

              > span {
                ${font13_18(true, 400)}
                opacity: .3;

                ${mq.greaterThan('tablet')} {
                  ${font13_18(false, 400)}
                }
              }
            }
          }

        }

        .mobile_tags {
          ${font11_13(true, 400)}
          align-items: center;
          opacity: .3;

          .tag_selected {
            font-style: italic;
          }
        }

        .time {
          padding: 0;

          &.dots {
            align-items: center;
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: center;
            margin-left: auto;
            opacity: .3;
            position: relative;
            width: 20px;

            ${mq.greaterThan('tablet')} {
              margin-left: unset;
              opacity: 1;
            }

            > button {
              background: transparent;
              border: unset;
              display: flex;
              height: 20px;
              justify-content: center;
              width: 20px;

              > figure {
                height: 20px;
                width: 20px;
              }
            }
          }
        }

        .hours {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 0;
          height: unset;
          position: relative;

          ${mq.greaterThan('tablet')} {
            height: 30px;
          }

          &.base {
            align-items: baseline;
          }

          &.high {
            height: auto;
            min-height: unset;

            ${mq.greaterThan('tablet')} {
              min-height: 30px;
            }
          }

          > p {
            ${font11_13(true, 400)}
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            max-width: 200px;

            ${mq.greaterThan('tablet')} {
              ${font13_18(false, 400)}
              gap: 0;
            }
          }

          .tag_selected {
            ${font11_13(true, 400)}
            opacity: .3;
            font-style: italic;

            ${mq.greaterThan('tablet')} {
              ${font13_18(false, 400)}
              font-style: normal;
            }
          }
        }

        label {
          height: min-content;
          margin: 0;
          min-height: 30px;
          padding: 0;
        }

        textarea {
          ${font13_19(true, 400)}
          background-color: transparent;
          border-bottom: unset;
          color: var(--color-text);
          height: min-content;
          margin: 0;
          min-height: 30px;
          padding: 0;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            -webkit-text-fill-color: var(--color-text);
            -webkit-box-shadow: 0 0 0 500px var(--color-text) inset;
            box-shadow: 0 0 0 100px var(--color-text) inset;
            color: inherit;
          }

          &::placeholder {
            color: var(--color-text);
            opacity: 1;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          ${mq.greaterThan('tablet')} {
            ${font13_19(true, 400)}
          }
        }
      }
    }
  }
`

const InputStyled = styled.input`
  background-color: transparent;
  border-bottom: unset;
  color: var(--color-text);
  font-size: 16px;
  font-weight: 600;
  height: unset;
  max-width: 22px;

  ${mq.greaterThan('tablet')} {
    height: 30px;
    font-size: 20px;
    max-width: 15px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-text);
    -webkit-box-shadow: 0 0 0 500px var(--color-text) inset;
    box-shadow: 0 0 0 100px var(--color-text) inset;
    color: inherit;
  }
  &::placeholder {
    color: var(--color-text);
    opacity: 1;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const week =( $isGrouped ) => css`
  ${$isGrouped ? 'padding: 15px 0 0;' : 'padding: 15px;'}
  align-items: flex-start;
  align-self: stretch;
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  > hgroup {
    ${$isGrouped ? 'flex-direction: row;' : 'flex-direction: column;'}
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    > div {
      ${$isGrouped ? 'order:2;' : 'order:unset;'}
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > h3 {
        ${$isGrouped ? `order: 2; ` : 'order: unset; '}
        ${font13_18(true, 400)}
        text-transform: uppercase;

        ${mq.greaterThan('tablet')} {
          ${font13_18(false, 400)}
        }
      }

      > span {
        ${$isGrouped ? `
        font-size: 12px;
        line-height: 19px;
      ` :
        `
        ${font20_25(true, 600)}

        ${mq.greaterThan('tablet')} {
          ${font20_25(false, 600)}
        }

      `}
      }
    }

    p {
      ${$isGrouped ? `
        order:1;
        font-size: 12px;
        line-height: 19px;
      ` :
        `
        order:unset;
        ${font20_25(true, 400)}

        ${mq.greaterThan('tablet')} {
          ${font20_25(false, 400)}
        }

      `}
    }
  }
`

const Article = styled.article<{ $type: string, $isGrouped: boolean }>`
  background-color: ${({ $isGrouped }) => !$isGrouped ? rgbAlpha('--color-white', .05) : 'transparent'};
  display: flex;
  height: 100%;

  ${({ $type, $isGrouped }) => $type === 'day' ? day : week($isGrouped)}
`
